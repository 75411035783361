import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import DvrPannellum from '../components/pannellum'
import DvrPannellumMenu from '../components/superSimpleViewMenu'
import DvrConnectionPanel from '../components/connect-prompt'
import {showAppbar, hideAppbar} from '../actions/appbar'
import {DVR_SELECT_CAMERA} from '../actions/types'

const styles = {
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
  hflex: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  grow: {
    flexGrow: 1
  }
}

class DvrProject extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {classes, users, sessionId, options, updateCameraPosition, pitch, yaw, hfov, minPitch, maxPitch, minYaw, maxYaw} = this.props
    return (
      <React.Fragment>
        <DvrPannellum image={options.url} pitch={pitch} yaw={yaw} hfov={hfov} minPitch={minPitch} maxPitch={maxPitch} minYaw={minYaw} maxYaw={maxYaw} stereo={options.stereo} multiRes={options.multires} handleUpdateCameraPosition={updateCameraPosition} camera={options.camera} hotSpots={options.hotSpots} selectCamera={this.props.selectCamera} sessionId={sessionId} />
        <DvrPannellumMenu users={users} options={options} handleUpdateCameraPosition={updateCameraPosition} sessionId={sessionId} /> 
      </React.Fragment>
    )
  }

  componentDidMount() {
    this.props.hideAppbar()
    this.props.loadModel(this.props.match.params.id)
    this.forceUpdate()
  }

  componentWillUnmount() {
    this.props.showAppbar()
  }
}

function mapStateToProps(state) {
  return {
    users: state.collaborate.users ? state.collaborate.users : {},
    sessionId: state.collaborate.sessionId,
    options: state.options ? state.options : {url:''},
    pitch: state.collaborate.pitch,
    yaw: state.collaborate.yaw,
    hfov: state.collaborate.hfov,
    minPitch: state.options.minPitch,
    maxPitch: state.options.maxPitch,
    minYaw: state.options.minYaw,
    maxYaw: state.options.maxYaw,
    connected: true
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectCamera: (sessionId, value) => {
      dispatch({type: DVR_SELECT_CAMERA, sessionId, value})
    },
    loadModel: (modelId) => { dispatch({type: 'MODEL_FETCH_REQUESTED', modelId}) },
    updateCameraPosition: (pitch, yaw, hfov) => { dispatch({type: 'CAMERA_POSITION', pitch, yaw, hfov}) },
    showAppbar: () => { dispatch(showAppbar()) },
    hideAppbar: () => { dispatch(hideAppbar()) }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrProject))
