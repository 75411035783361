import React from 'react'
import {connect} from 'react-redux'
import {withStyles,Toolbar,MenuItem,Drawer,CssBaseline,List,Menu,Button,Fab,IconButton } from '@material-ui/core'
import {DVR_SELECT_CAMERA} from '../actions/types'
import {selectOption, createSessionRequest} from '../actions/collaborate'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import SettingsIcon from '@material-ui/icons/ViewModule'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import green from '@material-ui/core/colors/green'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import "./slider_pannellam.css"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ShareSessionDialog from './share-session-dialog'
import JoinSessionDialog from './join-session-dialog'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Redirect } from 'react-router'
import * as clipboard from 'clipboard-polyfill'
import './customCss.css';
const drawerWidth   = 240
const drawerHeight  = 140
const styles = theme => ({
  backDrop: {
//    background: 'rgba(0,0,0,0)',
  },
  controller: {
  },
  drawer: {
  },
  button: {
    'display': 'block',
    'background': '#ececec',
    'margin': '0px 10px 10px 0px',
    'width': '100%',
  },
  root: {
    display: 'block',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  controlTab: {
    minHeight: '22px'
  },
  appBarGrow: {
    flexGrow: 1
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  menuButton: {
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    maxWidth: '1200px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('xs')]: {
      margin: ' auto 0px 0px 0px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'auto auto 0px auto',
    },
  },
  gridList: {
    background: '#BBB',
    '-webkit-box-shadow': 'inset 0px -15px 50px 0px rgba(133,133,133,0.75)',
    '-moz-box-shadow': 'inset 0px -15px 50px 0px rgba(133,133,133,0.75)',
    'box-shadow': 'inset 0px -15px 50px 0px rgba(133,133,133,0.75)',
  },
  closeIcon: {
    color: 'white'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    bottom: '20px',
    right: '30px',
    'z-index': '2',
    'background-color': '#3f51b5 !important'
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    position: 'fixed',
    bottom: '20px',
    right: '30px',
    'z-index': '9',
    'background-color': '#4caf50 !important'
  },
  siderbarnav:{
    'padding-top':'64px'
  },
  sidebarnavopen:{
    'margin-right':'240px !important'
  },
  sidebarnavclose:{
    'margin-right':'0px !important'
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing.unit,
  },
  MenuItemClass: {
    '&:hover': {
      color: '#2bd5c6 !important',
    }
  },
  MenuImgclass:{
    
  },
  titleBar: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  VRModeClass:{
    position: 'fixed',
    bottom:'20px',
    left: '20px',
    background:'transparent',
    'box-shadow':'none',
    border: 'none',
    '&:hover':{
      background:'transparent',
      'box-shadow':'none',
      border: 'none',
    }
  },
  BackButton:{
    position: 'fixed',
    zIndex: 10,
    top:'0px',
    left: '0px',
    background:'transparent',
    'box-shadow':'none',
    border: 'none',
    '&:hover':{
      background:'transparent',
      'box-shadow':'none',
      border: 'none',
    }
  },
  drawerContent: {
    overflow: 'hidden'
  },
  imageClass: {
    'moz-user-select': 'none',
    '-webkit-user-select': 'none',
    'user-select': 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sliderCell: {
    position: 'relative',
  },
  sliderTitleText:{
    fontSize: '16px',
    width: '100%',
    color: '#555555',
    background: '#cccccc'
  },
  sliderCellTitle: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    color: '#ccc',
    bottom: 0,
    left: 0
  },
})

function translateName(name) {
  if (name.startsWith('.')) {
    return name.split(':')[1]
  } else {
    return name
  }
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingBottom: 0, background: '#000C' }}>
      {props.children}
    </Typography>
  )
}

const hasOptions = options => {
  return Object.keys(options.available.materials).length || Object.keys(options.available.objects).length
}

const timeoutLength = 300;
class DvrPannellumMenu extends React.Component {
  constructor(props){
    super(props)
    console.log('PROPS', props)
    this.state= {
      setOpen:false,
      homePageRedirect:false,
      open:false,
      anchorEl: null,
      setAnchorEl:null,
      targetElement:'',
      mouseOverButton: false,
      mouseOverMenu: false,
      optTab: '',
      //currenUrlPart :'',
      currentUrlPath :location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '') + '/project/' + props.options.modelId
    }
    this.selectCameraUrlChange = this.selectCameraUrlChange.bind(this);

    this.sliderSettings = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      variableWidth: false,
      dots: false,
      focusOnSelect: false,
      swipeToSlider: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    }
  }

  handleDrawerOpen() {
    this.setState({open:true,setOpen:true})
  }

  handleDrawerClose() {
    this.setState({open:false,setOpen:false})
  }

  handleEscKey = e => {
    if (e.keyCode === 27) this.handleCloseDrawer();
  }

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  componentDidMount(){
    window.onpopstate = function(event) {
      history.go(1);
    };
  }

  componentDidUpdate(){
    window.onpopstate = function(event) {
      history.go(1);
    };
  }

  handleBtnClick(event) {
    event.preventDefault();
    this.setState({Menuopen:true,anchorEl:event.currentTarget,targetElement:event.currentTarget.name})
  }

  handleMenuClose() {
    this.setState({anchorEl:null, currentTarget:'',mouseOverButton: false, mouseOverMenu: false });
  }
  
  handleBackBtnClick(){
    this.setState({homePageRedirect:true})
  }

  MouseEnterButton = (event) => {
    event.preventDefault();
    this.setState({anchorEl:event.currentTarget, targetElement:event.currentTarget.name,mouseOverButton: true});
  }

  MouseLeaveButton = (event) => {
    event.preventDefault();
    setTimeout(() => {
      this.setState({mouseOverButton: false});
    }, timeoutLength);
  }

  OpenMenuPopup = () => {
    this.setState({ mouseOverMenu: true });
  }

  CloseMenuPopup = () => {
     setTimeout(() => {
     this.setState({anchorEl:'', targetElement:'',mouseOverMenu: false });
     }, timeoutLength);
  }

  handleTabChange = (event, tab) => {
    this.setState({tab})
  }

  handleOptTabChange = (event, optTab) => {
    this.setState({optTab})
  }

  selectCameraUrlChange(viewUrl){
    this.props.options.url = viewUrl;
  }

  onClick(sessionId, option, value,pitch,yaw,viewUrl) {
    const url = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '') + '/project/' + this.props.options.modelId
    this.handleCloseDrawer()
    this.selectCameraUrlChange(url)
    this.props.selectCamera(sessionId, option, url, 0, 0);
  }

  handleCloseDrawer(){
   this.setState({open:false,setOpen:false})
  }

  copyToClipboard() {
    clipboard.writeText('https://' + window.location.hostname + '/collaborate/' + this.props.sessionId)
  }
  
  selectOption(sessionId, optTab, val) {
    if (!this.sliding) {
      this.props.selectOption(sessionId, optTab, val)
      this.handleCloseDrawer()
    }
  }

  sliderBefore = (index) => {
    this.sliding = true
  }

  sliderAfter = (index) => {
    this.sliding = false
  }

  render() {
    const {classes, users, sessionId, options, handleShareSession, selectCamera} = this.props

    if (!options) return null

    const Menuopen = true
    const views = options.views
    const tab = this.state.tab ? this.state.tab : (hasOptions(options) ? 'options' : 'views')
    const optTab = this.state.optTab ? this.state.optTab : [...Object.keys(options.available.objects), ...Object.keys(options.available.materials)][0]
    const i18n = function(s) {
      return options.i18n[s] ? options.i18n[s] : s
    }
    const MyHomeLink = props => <Link to="/home" {...props}  refresh="true"/>
    const anchorEl = this.state.anchorEl;

    return (
      <React.Fragment>
        <CssBaseline />
          <Drawer
              onKeyUp={this.handleEscKey}
              classes={{paper: classes.drawerPaper}}
              ModalProps={{BackdropProps: {classes: {root: classes.backDrop}}}}
              variant="temporary"
              anchor="bottom"
              open={this.state.open}
              onClose={this.handleCloseDrawer.bind(this)}
            >
         
            <div className={classes.drawerContent}>
              <TabContainer>
                <Tabs value={optTab} onChange={this.handleOptTabChange} variant="scrollable" scrollButtons="auto" style={{background:'rgba(0.1,0.1,0.1,0.7)', color: '#fff'}} classes={{root: classes.controlTab}}>
                  {Object.keys(options.available.objects).sort().map(opt =>
                    (options.available.objects[opt].length > 1) && <Tab classes={{root: classes.controlTab}} value={translateName(opt)} label={i18n(translateName(opt))} />
                  )}
                  {Object.keys(options.available.materials).sort().map(opt =>
                    (options.available.materials[opt].length > 1) && <Tab classes={{root: classes.controlTab}} value={translateName(opt)} label={i18n(translateName(opt))} />
                  )}
                </Tabs>
                  {options.available.objects[optTab] &&
                    <>
                      <Slider {...this.sliderSettings} beforeChange={this.sliderBefore} afterChange={this.sliderAfter}>
                        {options.available.objects[optTab].map(val => 
                           <div className={classes.sliderCell} onClick={this.selectOption.bind(this, sessionId, optTab, val)}>
                           <img style={{height: "80px"}} name={val} alt={val} src={'https://s3.amazonaws.com/dvr-panobuilder-renders/' + options.modelId + '/thumbnails/materials/' + translateName(val) + '.png'} className={classes.imageClass} />
                          </div>
                        )}
                      </Slider>
                    </>
                  }
                  {options.available.materials[optTab] && 
                    <>
                      <Slider {...this.sliderSettings} beforeChange={this.sliderBefore} afterChange={this.sliderAfter}>
                        {options.available.materials[optTab].map(val => 
                           <div className={classes.sliderCell} onClick={this.selectOption.bind(this, sessionId, optTab, val)}>
                           <img style={{height: "80px"}} name={val} alt={val} src={'https://s3.amazonaws.com/dvr-panobuilder-renders/' + options.modelId + '/thumbnails/materials/' + translateName(val) + '.png'} className={classes.imageClass} />
                          </div>
                        )}
                      </Slider>
                    </>
                  }
              </TabContainer>
            </div>
        </Drawer>
        <Fab color="primary" aria-label="Add" onClick={this.handleDrawerOpen.bind(this)} className={classNames(classes.fab, this.state.open && classes.hide)}>
              <SettingsIcon />
        </Fab>
       </React.Fragment>
     ) 
  } 
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleShareSession: ev => {
      dispatch(createSessionRequest())
    },
    handleJoinSession: sessionId => {
      dispatch(joinSessionRequest(sessionId))
    },
    selectOption: (sessionId, option, value) => {
      dispatch(selectOption(sessionId, option, value))
    },
    selectCamera: (sessionId, value,currentUrlPath,viewpitch,viewyaw) => {
      history.replaceState({}, null, currentUrlPath+'/'+value+'/'+viewpitch+'/'+viewyaw);
      dispatch({type: DVR_SELECT_CAMERA, sessionId, value})
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrPannellumMenu))
